.nav {
    &-tabs {
        border-bottom: none;
        // margin-left: 1.25rem;
        .nav-item {

            text-transform: uppercase;

            .active {
                border-color: transparent;
            }
            .nav-link {
                line-height: 1.5rem;
            }
        }
    }
    &-pills {
        margin-bottom: 1.25rem;
    }
}

.tab-content {
    > .tab-pane {
        > .card {
            border-top-left-radius: 0;
        }
    }
}

.mc-tabs {
    &--horizontal {
        .nav-tabs {
            @include media-breakpoint-down(sm) {
                background-color: #fff;
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                padding-top: .25rem;
                .nav-link {
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                    &:hover,
                    &:focus {
                        border-color: transparent;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                font-size: .75rem;
            }
        }
        .tab-content {
            > .tab-pane {
                > .card {
                    @include media-breakpoint-down(sm) {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }
    }
    &--vertical {
        @include mc-display-flex();
        .nav-tabs {
            padding: .5rem 0;
            min-width: 11rem;
            > .nav-item {
                > .nav-link {
                    font-size: .75rem;
                    padding: 0.3rem 1rem;
                    border-radius: 0.25rem;
                    border: none;
                }
            }
        }
        .tab-content {
            @include mc-flex-grow-1();
            @include mc-display-flex();
            > .tab-pane {
                @include mc-flex-grow-1();
                @include mc-display-flex();
            }
        }
    }
}
