@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';

.dashboard {
  min-height: 100vh;
  &--light {
    background: #CEE1E9;
    /* background: -moz-linear-gradient(180deg, rgba(204,224,233,1) 0%, rgba(238,238,238,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(204,224,233,1) 0%, rgba(238,238,238,1) 100%);
    background: linear-gradient(180deg, rgba(204,224,233,1) 0%, rgba(238,238,238,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cce0e9",endColorstr="#eeeeee",GradientType=1); */
  }
  &--medium {
    background: #FFE7DD;
    /* background: -moz-linear-gradient(180deg, rgba(232,214,193,1) 0%, rgba(231,230,230,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(232,214,193,1) 0%, rgba(231,230,230,1) 100%);
    background: linear-gradient(180deg, rgba(232,214,193,1) 0%, rgba(231,230,230,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8d6c1",endColorstr="#e7e6e6",GradientType=1); */
    .card-container {
      .card {
        &--link,
        &--shopping-cart,
        &--shopping-order {
          background-color: #f9f5f2;
        }
      }
    }
  }
  &--dark {
    background: #50608C;
    /* background: -moz-linear-gradient(180deg, rgba(59,80,98,1) 0%, rgba(108,117,125,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(59,80,98,1) 0%, rgba(108,117,125,1) 100%);
    background: linear-gradient(180deg, rgba(59,80,98,1) 0%, rgba(108,117,125,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b5062",endColorstr="#6c757d",GradientType=1); */
    .card-container {
      .card {
        &-title {
          color: $white;
        }
        &--link,
        &--shopping-cart,
        &--shopping-order {
          background-color: $gray-300;
        }
      }
    }
  }
  .card {
    .card-title {
      font-size: 1rem;
      font-weight: 300;
    }
    > .card-body {
      &--bg::before {
        content: "";
        background-repeat: no-repeat;
        opacity: 0.2;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 0;
      }
    }
    ul {
      > li {
        line-height: 1.5rem;
        .badge {
          background-color: rgba($white, .15);
        }
      }
    }
  }
}
.dashboard--dark--text {
  color: #FFF !important;
}