.contextual-menu {
    width: fit-content;
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 4.25rem;
    right: 0;
    bottom: 0;
    padding-top: 1.5rem;
    background-color: #fff;
    z-index: 1010;
    @include mc-transform(translateX(0));
    @include mc-transition(transform 0.3s ease-in-out);

    &--collapsed {
      @include mc-transform(translateX(100%));
    }
    .nav {
      &-link {
        padding: 1.125rem .5rem 1.125rem .875rem;
        font-size: 1rem;
        font-weight: 400;
        > mc-icon {
          margin-right: 1rem;
        }
      }
    }
  }
  .nav {
    &-link {
      padding-left: 0;
      > mc-icon {
        margin-right: .5rem;
      }
      &.disabled {
        color: $primary !important;
      }
    }
  }
}