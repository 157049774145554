.mc-toast {
    width: 24.2rem !important;
    display: block;
    position: relative;
    bottom: -100%;
    right: 3.25rem;
    color: white;
    padding: 0 0.875rem;
    margin-top: 0.75rem;
    border-radius: 0.125rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.25);
    pointer-events: auto;
    overflow: hidden;
    cursor: pointer;

    &__ripple {
        height: 25rem;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 50%;
        animation: ripple 0.4s ease-out forwards;
        transform-origin: bottom right;
        transform: scale(0) translateY(-51%) translateX(13%);
        z-index: 5;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-right: 1rem;
        border-radius: 0.25rem;
        position: relative;
        z-index: 10;
    }

    &__message {
        display: flex;
        position: relative;
        opacity: 0;
        animation: fadeinup 0.3s forwards;
        animation-delay: 0.25s;
        word-break: break-word;

        .card-text {
            font-size: 1rem;
            font-weight: 600;
        }

        &__icon {
            i {
                font-size: 1.5rem;
                margin: 0 0.875rem;
            }
        }
    }

    &--success {
        .mc-toast__ripple {
            background-color: #28a745;
        }
    }

    &--danger {
        .mc-toast__ripple {
            background-color: #dc3545;
        }
    }

    &--warning {
        // color: $dark;
        color: #666666 !important;

        .mc-toast__ripple {
            background-color: #FFF5D8;
        }
    }

    &--info {
        color: $dark !important;

        .mc-toast__ripple {
            border-right: 5px solid #0d6efd;
            background-color: #E8F2FF;
        }
    }
}

.toast-top-right {
    top: calc(3.9rem + 12px);
}

@keyframes fadeinup {
    0% {
        opacity: 0;
        transform: translateY(25%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ripple {
    0% {
        transform: scale(0) translateY(-45%) translateX(13%);
    }

    100% {
        border-radius: 0%;
        transform: scale(1) translateY(-15%) translateX(0%);
    }
}

@media screen and (max-width: 767.98px) {
    .toast-top-right {
        top: 0;
    }
}

@media screen and (max-width: 575.98px) {
    .mc-toast {
        width: auto !important;
        min-width: 20rem;
        right: 0;
        padding: 0 !important;
    }
}