// FONTS
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

// BOOTSTRAP 4
@import './variables';
@import './node_modules/bootstrap/scss/bootstrap';

// FONT AWESOME
// @import './node_modules/@fortawesome/fontawesome-pro/css/all.min.css';
@import '../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css';

// NG SELECT THEME
//@import "~@ng-select/ng-select/themes/default.theme.css";

// TOAST BOOTSTRAP 4 THEME
@import '../../node_modules/ngx-toastr/toastr-bs4-alert';
@import './mixins/main';
@import './form-fields';
@import './input';
@import './card';
@import './pagination';
@import './badge';
@import './dropdown';
@import './alert';
@import './toast';
@import './nav';
@import './spinner';
@import './modal';
@import './button';
@import './status-led';
@import './slider';
@import './link';
@import './sidebar';
@import './breadcrumb';
@import './contextual-menu';
@import './dashboard';

/* DECLARACION FONT SIZE RAIZ */
html {
  font-size: 16px;
}

/* ######################### */

* {
  --primario: #fe30af;
  --primario-hover: #ff58b3;
  --secundario: #3807b0;
  --secundario-hover: #5d27c8;
  --link: #2f80ed;
  --link-disabled: #a1c4f4;
  --texto: #666666;
  --texto2: #4f4f4f;
  --disabled: #bdbdbd;
  --destacados: #ebebff;
  --error: #dc3545;
  --advertencia: #ffc107;
  --aprobado: #28a745;
  --white: #ffffff;
}

menu-pages,
app-dashboard,
app-add-page,
app-basic-info,
app-contact-data,
app-design,
app-settings,
app-main,
app-emails,
app-integrations,
app-seo-tools,
app-website,
app-cart,
app-edit-page,
app-user-profile,
app-user,
app-e-commerce,
mc-error-page,
app-not-found,
app-internal-server-error {
  width: 100% !important;
  min-height: 100vh;
}

// COLORES CLASES //
/* Color Font */
.color-primario {
  color: var(--primario);
}

.color-primario-hover:hover {
  color: var(--primario-hover);
}

.color-secundario {
  color: var(--secundario);
}

.color-secundario-hover:hover {
  color: var(--secundario-hover);
}

.color-link {
  color: var(--link) !important;
}

.color-link-disabled {
  color: var(--link-disabled) !important;
}

.color-texto {
  color: var(--texto);
}

.color-texto2 {
  color: var(--texto2);
}

.color-disabled {
  color: var(--disabled);
}

.color-destacados {
  color: var(--destacados);
}

.color-error {
  color: var(--link);
}

.color-advertencia {
  color: var(--advertencia);
}

.color-aprobado {
  color: var(--aprobado);
}

.color-white {
  color: var(--white);
}

/* Background Color */
.bg-color-primario {
  background-color: var(--primario);
}

.bg-primario-hover:hover {
  background-color: var(--primario-hover);
}

.bg-color-secundario {
  background-color: var(--secundario);
}

.bg-secundario-hover:hover {
  background-color: var(--secundario-hover);
}

.bg-color-link {
  background-color: var(--link);
}

.bg-color-texto {
  background-color: var(--texto);
}

.bg-color-disabled {
  background-color: var(--disabled);
}

.bg-color-destacados {
  background-color: var(--destacados);
}

.bg-color-error {
  background-color: var(--link);
}

.bg-color-advertencia {
  background-color: var(--advertencia);
}

.bg-color-aprobado {
  background-color: var(--aprobado);
}

.bg-color-white {
  background-color: var(--white);
}

// END COLOR CLASES //

body {
  background-color: #fafafa;
  line-height: 1.428;
  font-size: 0.875rem;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

input::placeholder {
  font-style: italic;
  opacity: 0.1;
}

textarea::placeholder {
  font-style: italic;
  opacity: 0.4;
}

input[type='checkbox']:checked:after,
.checkbox input[type='checkbox']:checked:after,
.checkbox-inline input[type='checkbox']:checked:after {
  background-color: var(--secundario) !important;
  border-color: var(--secundario) !important;
}

input[type='checkbox']:focus:after,
.checkbox input[type='checkbox']:focus:after,
.checkbox-inline input[type='checkbox']:focus:after {
  border-color: var(--secundario) !important;
}

input[type='radio']:before,
.radio input[type='radio']:before,
.radio-inline input[type='radio']:before {
  background-color: var(--secundario) !important;
}

input[type='radio']:checked:after,
.radio input[type='radio']:checked:after,
.radio-inline input[type='radio']:checked:after {
  border-color: var(--secundario) !important;
}

.logo-ss {
  width: 10rem;
}

.container-fluid {
  &--limited {
    max-width: 80rem;
  }
}

.container {
  @include mc-transition(padding-left 0.3s ease-in-out);

  @include media-breakpoint-up(md) {
    padding-left: 4.0625rem;
  }

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

strong {
  font-weight: 600;
}

blockquote {
  padding: 0 0 0 1.5rem;
  margin: 0;
  font-size: inherit !important;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.overflow {
  &-visible {
    overflow: visible !important;
  }

  &-auto {
    overflow: auto !important;
  }

  &-hidden {
    overflow: hidden !important;
  }

  &-x {
    &-auto {
      overflow-x: auto !important;
    }

    &-hidden {
      overflow-x: hidden !important;
    }
  }

  &-y {
    &-auto {
      overflow-y: auto !important;
    }

    &-hidden {
      overflow-y: hidden !important;
    }
  }
}

.text-transform-none {
  text-transform: none;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break-all {
  word-break: break-all;
}

.mc-list-filter {
  .form-group {
    margin-bottom: 0;
  }
}

.spinner-wrapper {
  &.logged:not(.admin) {
    .loader {
      margin: 0 auto;

      @media (min-width: 768px) {
        & {
          left: 15.625rem;
        }
      }
    }
  }
}

.mc-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.31);
}

.carousel {
  &--apps {
    .slick-track {
      @include mc-display-flex();
    }

    @each $breakpoint,
    $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        width: $container-max-width - 280px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

/**
 * Fix componentes login
 */
.label-no-context {
  color: #7d7d7d;
  font-size: 0.95em;
  font-weight: 400;
  margin: 0 0 0.125rem 0;
  position: relative;
}

.ss-btn-primary {
  align-items: center !important;
  gap: 0.625rem !important;
  padding: 0.625rem 1.25rem !important;
  border-radius: 0.25rem !important;
  background-color: var(--secundario) !important;
  border: none !important;
  color: var(--white) !important;
  text-transform: none !important;
}

.ss-btn-primary:hover {
  background-color: var(--secundario-hover) !important;
}

.ss-btn-primary:focus {
  background-color: var(--secundario-hover) !important;
}

.ss-btn-secondary {
  align-items: center !important;
  gap: 0.625rem !important;
  padding: 0.625rem 1.25rem !important;
  border-radius: 0.25rem !important;
  border-color: var(--secundario);
  color: var(--secundario);
  background-color: var(--white);
  text-transform: none !important;
}

.ss-btn-secondary:hover {
  border-color: var(--secundario-hover);
  color: var(--secundario-hover);
}

.ss-btn-secondary:focus {
  border-color: var(--secundario-hover);
  color: var(--secundario-hover);
}

.pages-section .mat-dialog-container {
  background-color: #ff0000 !important;
}

.menu-pages-title:hover {
  span.badge {
    display: none !important;
  }

  .update-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subpage-content:hover {
  span.badge {
    display: none !important;
  }

  .update-subpage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.empty-categories-modal>.modal-dialog {
  max-width: 36.25rem !important;
}

.empty-categories-modal>.modal {
  top: 4.0625rem !important;
}

.full-screen-modal>.modal-dialog {
  min-width: 100% !important;
}

.modal-qr>.modal-dialog {
  max-width: 25.1875rem !important;
}

.stepper-modal>.modal-dialog {
  max-width: 80% !important;
  width: 50% !important;
}

.stepper-modal>.modal {
  top: 4.0625rem !important;
}

.stepper-modal .modal-content {
  padding: 0 3rem;
}

.template-wizard>.modal-dialog {
  width: 50% !important;
}

.createMailAccountModal .modal-header {
  padding: 1rem 1rem 0 1rem;
}

.createMailAccountModal .modal-dialog {
  width: 85% !important;
}

.body-sectionEmails .mat-form-field-underline {
  bottom: 0 !important;
}

.body-sectionEmails .mat-paginator-page-size {
  align-items: center !important;
}

@media screen and (min-width: 576px) {
  .createMailAccountModal .modal-dialog {
    max-width: 50rem;
    margin: 1.75rem auto;
  }

  .template-wizard .modal-dialog {
    max-width: 45rem;
  }
}

@media screen and (max-width: 576px) {
  .template-wizard>.modal-dialog {
    width: 85% !important;
  }
}

@media (min-width: 200px) and (max-width: 370px) {
  .mobile-paginate {
    flex: 1 0 0% !important;
    max-width: none !important;
  }
}

/* Margin Modal Pages */
.margin-modal-pages {
  margin-left: 220px;
}

/* Margin Modal Pages */

//INITIAL-WIZARD
.full-screen-wizard {
  // position: fixed;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1050 !important;
}

.scroll-y-auto {
  overflow-y: auto;
}

.scroll-y-auto::-webkit-scrollbar {
  width: 0.625rem;
  border-radius: 0.5rem;
}

.scroll-y-auto::-webkit-scrollbar-track {
  background: #e9eef1;
}

.scroll-y-auto::-webkit-scrollbar-thumb {
  width: 0.625rem;
  border-radius: 0.5rem;
  background-color: var(--texto);
}

//SELECT SCROLLBAR STYLES
/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.select-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.select-scrollbar::-webkit-scrollbar:vertical {
  width: 0.5rem;
}

.select-scrollbar::-webkit-scrollbar-button:increment,
.select-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.select-scrollbar::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

.select-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 1.25rem;
  border: 2px solid #f1f2f3;
}

.select-scrollbar::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}

.select-scrollbar li {
  cursor: pointer;
}

.select-scrollbar li:hover {
  background-color: #f4f3f3;
}

//Custom selectCodCountry
.selectCodCountry .width-img-flag {
  width: 100%;
  max-width: 32px;
  height: 22px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0.125rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-dialog {
  width: 45% !important;
}

@media (max-width: 991.88px) {
  .modal-dialog {
    width: auto !important;
    max-width: 90% !important;
    margin: 0 auto;
  }
}

// END INITIAL WIZARD

//STEPPER //
.title-template {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.subtitle-content {
  font-size: 0.875rem;
  letter-spacing: normal;
}

//NEW//
.form-control {
  border-color: #adadad !important;
  box-shadow: 0 0 1rem #adadad33 !important;

  &::-ms-reveal {
    display: none;
  }
}

.form-control:hover {
  border-color: var(--texto) !important;
}

.form-control:focus {
  border-color: var(--secundario) !important;
  background-color: var(--white) !important;
  box-shadow: 0 0 1rem #3807b033 !important;
}

.wrapper-stepper {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.fas.fa-circle,
.fas.fa-check-circle {
  font-size: 1.2rem;
}

.fas.fa-check-circle {
  color: var(--primario);
  background-color: var(--white);
  border-radius: 0.625rem;
}

.btn-div {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-div>.btn-link {
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
}

.btn-skip {
  min-width: 6.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  border: 1px solid var(--primario);
  color: var(--primario) !important;
  background-color: var(--white);
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
}

.btn-skip:hover {
  border-color: var(--primario-hover);
  color: var(--primario-hover) !important;
}

.btn-skip:focus {
  border-color: #c11874 !important;
  color: #c11874 !important;
}

.btn-continue {
  min-width: 6.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  white-space: break-spaces;
  background-color: var(--primario);
  color: var(--destacados);
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;

  &:disabled,
  &:hover:disabled {
    color: #fff;
    background-color: #bdbdbd;
    opacity: 1 !important;
  }
}

.btn-continue:hover {
  background-color: var(--primario-hover);
  color: var(--destacados);
}

.btn-continue:focus {
  background-color: var(--primario-hover);
  color: var(--destacados);
}

.btn-descubrir {
  border-radius: 0.25rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.5rem;
  font-size: 0.875rem;
  color: var(--destacados);
  background-color: var(--primario);
}

.btn-descubrir:hover {
  background-color: var(--primario-hover);
}

.step-opacity {
  opacity: 0.4;
}

.step-opacity>.text-span {
  background-color: var(--destacados);
}

.step-pass {
  opacity: 1 !important;
}

.form-group label {
  color: var(--texto) !important;
  font-size: 1rem !important;
  font-weight: 400;
  margin: 0 0 0.25rem 0;
  position: relative;
}

button {
  outline: 0 !important;
}

//END STEPPER //

// HEADER SECTION //
.header-section {
  min-height: 5rem;
  position: sticky;
  top: 0;
  z-index: 97;
  background-color: #fafafa;
  display: flex;
  padding: 0 4rem;
  margin-bottom: 3rem;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1);

  .header-section__box {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .header-section__title {
    display: flex;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      font-family: 'Open sans';
      font-weight: 300;
      white-space: nowrap;
    }
  }

  .header-section__btn-action {
    height: 2.5rem;
    background-color: var(--primario);
    color: var(--white);
    border-radius: 0.25rem;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0 1rem;
  }

  .header-section__btn-action:hover {
    background-color: var(--primario-hover);
  }
}

// END HEADER SECTION //

// BODY SECTION //
.body-section .products-create-box {
  width: 100%;
  background: var(--white);
  border: 1px solid #dee1e9;
  border-radius: 5px;
}

.body-section {
  width: 100%;
  padding: 0 4rem 2rem;

  .body-section__wrapper {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .body-section__wrapper__box {
    padding: 0;
  }

  .body-section__wrapper__box__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    background-color: #edeff3;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: var(--secundario);
      margin-bottom: 0;
    }
  }

  .form-group {
    margin: 0 !important;

    label {
      font-size: 0.875rem !important;
      margin-bottom: 0.375rem !important;
    }
  }

  .body-section__wrapper__box__content {

    p,
    label,
    small {
      font-size: 0.875rem !important;
    }

    .form-check {
      display: inline-block !important;
    }
  }
}

@media screen and (max-width: 991.88px) {
  .body-section {
    padding: 0 1rem 2rem;
  }
}

// END BODY SECTION

// MATERIAL SELECT COUNTRY //
/* Section PAGES */
.page-header-select-language {
  input.mat-input-element {
    font-weight: 600;
    color: var(--link);
  }

  span label {
    display: none !important;
  }

  .mat-form-field-wrapper {
    border-bottom: 2px solid var(--link);
    padding-bottom: 0.25rem !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: var(--link) !important;
    display: none;
  }

  .mat-form-field-suffix {
    width: 35% !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    order: 2;
  }

  .mat-form-field-suffix .mat-icon {
    margin-left: 1rem;

    svg {
      width: 1.5rem;
    }
  }

  .mat-form-field-infix {
    width: 5.5rem !important;
  }
}

.menu-pages-actions,
.menu-subpages-actions {
  .mat-icon-button {
    padding: 0;
    width: 1.2rem !important;
    height: min-content !important;
    flex-shrink: 0;
    line-height: 0.5rem !important;
    border-radius: 0.25rem !important;
  }
}

.mat-menu-item:hover:not([disabled]) {
  background-color: #eff2f8 !important;
}

.create-page-modal {
  .mat-dialog-container {
    overflow: initial;

    .mat-button-focus-overlay {
      background-color: transparent;
      appearance: none;

      &:hover {
        background-color: transparent;
        appearance: none;
      }
    }
  }
}

/* End Section PAGES */
.cdk-overlay-container {
  z-index: 1500;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1500;
}

.cdk-overlay-pane {
  z-index: 1500;
}

.mat-form-field {
  width: 100% !important;
  height: 100% !important;
}

.mat-form-field-wrapper {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.mat-form-field-label-wrapper {
  top: 0 !important;
  padding: 0 !important;
}

.mat-form-field-flex {
  height: 100% !important;
  align-items: center !important;
  padding: 0 0.75rem !important;
  margin: 0 !important;
}

.mat-form-field-outline {
  top: 0 !important;
}

.mat-form-field-infix {
  width: 50% !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}

.mat-form-field-infix .mat-input-element {
  font-size: 0.875rem;
  line-height: 1;
  color: #495057;
  text-align: center;
  vertical-align: middle;
}

.mat-form-field-suffix {
  width: 50% !important;
  height: 100% !important;
  top: 0 !important;
  text-align: center;
  margin: 0 !important;
}

.mat-form-field-suffix .mat-icon,
.mat-option-text .mat-icon {
  width: 100% !important;
  max-width: 2.5rem !important;
  height: 100% !important;
  font-size: 1rem !important;
  margin: 0 auto !important;
}

.mat-autocomplete-panel {
  min-width: auto !important;
}

.mat-option {
  height: fit-content !important;
  padding: 0.5rem 0 !important;
  line-height: 1 !important;
  text-align: center !important;
  overflow: initial !important;
}

.mat-option-text {
  width: 100% !important;
  height: fit-content !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}

.mat-option-text .mat-icon {
  margin: 0 !important;
}

.mat-option-text .mat-icon svg {
  vertical-align: middle !important;
}

.mat-option-text small {
  font-size: 0.875rem !important;
  line-height: 1 !important;
}

.info-contacto .mat-form-field {
  height: 2.625rem !important;
  line-height: 1;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--texto);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #aaa;
  background-color: var(--white);
}

// para INFO de CONTACTO
.info-contacto .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  width: 100%;
  max-width: 6.375rem;
}

// para INFO de CONTACTO
.info-contacto .mat-form-field-appearance-outline .mat-form-field-flex {
  height: 2.8125rem !important;
}

// PARA CREAR PAGINA
.menu-page-mat-select .mat-form-field-appearance-outline .mat-form-field-wrapper {
  width: 8.125rem !important;
}

// MATERIAL SELECT COUNTRY //

// Statics => time filters
.time-filters,
.vertical-filters {
  .mat-select-value {
    color: #2f80ed;
  }

  .mat-select-arrow {
    color: transparent !important;
  }

  .mat-form-field-underline,
  .mat-form-field-underline:hover {
    background-color: #2f80ed;
    bottom: 0 !important;

    .mat-form-field-ripple {
      background-color: #2f80ed;
    }
  }
}

.vertical-filters {
  .mat-select-value {
    font-weight: 600;
  }
}

.mat-select-panel {
  max-height: fit-content !important;
  padding: 0.25rem 0 !important;
}

// ICONO LOGIN OJO VISIBLE //
mc-icon.position-absolute.cursor-pointer {
  top: 0.7rem;
}

// INPUT SS CHECKBOX RADIO
.form-check-inline {
  margin-bottom: 1rem !important;
}

// INPUT SS CHECKBOX
.header-data {
  .checkbox-market-publicado {
    label {
      small {
        font-size: 1rem !important;
        color: var(--texto) !important;
      }
    }
  }
}

.checkbox-sincro-automatica {
  label {
    small {
      font-size: .85rem !important;
    }
  }
}

.favicon #circle {
  width: 6.25rem !important;
  height: 6.25rem !important;
  font-size: 2.25rem !important;
}

.favicon #img-circle {
  width: 6.25rem !important;
  height: 6.25rem !important;
}

// PAGE-ERRORS
.page-error {
  width: 100%;
  height: 100%;
  background-color: var(--white);

  .img-container {
    width: 45%;
    min-width: 31.25rem;
  }

  .txt-container {
    text-align: center;
    margin: 3rem 0;

    h2 {
      font-size: 1.5rem;
      color: var(--texto);
    }

    p {
      font-size: 0.875rem;
      color: var(--texto);
      margin: 1.5rem 0 0 0;
    }
  }
}

// Page settings => lenguages
.modal-lenguages-completed {
  .mat-dialog-container {
    box-shadow: none !important;
    border-radius: 0.25rem !important;
  }
}

// Pages => menu => seo modal
.seo-modal-container {
  .mat-standard-chip {
    border: 1px solid #dee1e9;
    border-radius: 4px;

    button {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 0;
      background: none;
    }
  }

  .mat-standard-chip.mat-chip {
    background-color: #fafafa;
    color: var(--texto);
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding-right: 0;
  }

  .custom-seo-tooltip.show {
    opacity: 1;
  }
}

//Ecommerce
//Category modal
.create-category-products .mat-chip-list-wrapper input {
  border: 0 !important;
}

.padding-40 .mat-chip-list-wrapper {
  padding: 40px 0 !important;
}

// Variants modal
.modal-create-variant-products {
  .mat-chip.mat-standard-chip {
    background: #fafafa !important;
    box-shadow: 0px 2.1px 4.2px rgba(0, 0, 0, 0.15) !important;
    border-radius: 24px !important;

    &:hover {
      background: var(--destacados) !important;
    }

    &:focus {
      background-color: #fafafa !important;
    }
  }
}

.update-variant-modal {
  label {
    font-size: 0.875rem !important;

    small {
      font-size: 0.75rem !important;
      color: var(--texto);
    }
  }
}

// Tooltips
.arrow:before {
  border-right-color: var(--texto) !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: var(--texto) !important;
  border-right-color: #00000000 !important;
}

.tooltip-inner {
  color: var(--white);
  font-weight: 400;
  padding: 0.5rem;
  background-color: var(--texto);

  a {
    color: var(--white);
    font-weight: 600;
    text-decoration: underline;
  }
}

/* STATISTICS */
.not-found-statistics {
  width: 100%;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 0.25rem;
  border: solid 1px var(--destacados);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: var(--secundario);
}

.statistics-section .mat-select-trigger {
  line-height: 1.5rem !important;
}

/* STATISTICS */

// End pages buttons

/* Monaco Editor */
.monaco-editor {
  width: 100% !important;
}

.monaco-editor .overflow-guard {
  width: 100% !important;
}

/* Monaco Editor */

@media screen and (max-width: 1500px) {

  // para INFO de CONTACTO
  .info-contacto .mat-form-field-appearance-outline .mat-form-field-wrapper {
    max-width: 5.625rem;
  }
}

@media screen and (max-width: 1366px) {
  .wrapper-stepper {
    height: 100vh;
  }

  /* .mat-form-field {
    height: 2.375rem !important;
    line-height: 1 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    height: 2.5rem !important;
    padding: 0 0.625rem !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
    width: 5.625rem;
  }
  .mat-form-field-infix {
    width: 5.625rem !important;
    border-top: 0 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.42em 0;
  }
  .mat-form-field-suffix .mat-icon svg {
    width: 2rem;
    border-radius: 0.375rem;
  } */
  /* .mat-option-text .mat-icon svg {
    width: 1.75rem !important;
    border-radius: 0.375rem !important;
  }
  .mat-autocomplete-panel {
    min-width: 5.75rem !important;
  }
  .mat-option {
    padding: 0 0.75rem !important;
  }
  .mat-option .mat-icon {
    margin-right: 0.375rem !important;
  } */
  // para INFO de CONTACTO
  .info-contacto .mat-form-field {
    height: 2.25rem !important;
  }

  // para INFO de CONTACTO
  .info-contacto .mat-form-field-appearance-outline .mat-form-field-flex {
    height: 2.5rem !important;
  }
}

/* MAT DIALOG */
.mat-dialog-container {
  border-radius: 10px !important;
  padding: 2rem 1.5rem 2rem 2rem !important;
  margin-left: 15.5rem !important;
  /* Margin para el Sidebar */
}

/* Container Modal Form Pages Section */
.container-form-modal-pages {
  width: 36rem;
  max-height: 90vh;
  padding-right: 0.5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

/* Container Modal Form Pages Section */

// Modal new design
.design-modal {
  &-title {
    color: #666666;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }

  &-description {
    color: #666666;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.creation-menu {
  width: 200px !important;
  height: 136px !important;
}

.creation-menu-item {
  width: 198px !important;
  height: 40px !important;
  background-color: #fff !important;
}

.creation-menu-item:hover {
  background-color: #eff2f8 !important;
}

.new-element {
  width: 166px;
  height: 20px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.paginator {
  .mat-form-field {
    margin: 0 0.5rem !important;
    height: 1.5rem !important;
  }
}

.mat-paginator {
  background: none !important;
}

/* PAGINATOR PRODUCTS */
.paginator-products .mat-paginator-page-size {
  align-items: center !important;
}

.paginator-products .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0 !important;
}

.paginator-products .mat-paginator-range-label {
  margin: 0 0.5rem !important;
}

@media (max-width: 576.88px) {
  .paginator-products .mat-paginator-range-label {
    margin: 0 !important;
  }

  .paginator-products .mat-paginator-container {
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    padding: 0.5rem 0 !important;
  }

  .paginator-products .mat-icon-button {
    width: fit-content !important;
    height: auto !important;
    line-height: 1 !important;
  }

  .paginator-products .mat-paginator-page-size-select {
    margin: 0 !important;
    max-width: 5rem !important;
  }

  .paginator-products .mat-paginator-page-size-label {
    max-width: 14ch !important;
  }
}

/* END PAGINATOR PRODUCTS */

@media screen and (max-width: 1280px) {
  .info-contacto .mat-form-field-appearance-outline .mat-form-field-wrapper {
    width: 5rem;
  }
}

@media screen and (max-width: 991.98px) {
  .mat-dialog-container {
    padding: 1.5rem !important;
    margin-left: 0 !important;
    // max-height: 80vh !important;
  }

  // Page Section
  .create-page-modal {
    .mat-dialog-container {
      overflow: auto;
    }
  }

  .header-section {
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  .header-section__box {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575.98px) {
  html {
    font-size: 15px;
  }

  .content-logo-iconburguer {
    padding: 0 2rem;
  }

  .body-section {
    padding: 0 1rem 1rem;
  }

  .mat-menu-panel {
    max-height: 40vh !important;
  }

  .btn-skip {
    padding: 0.5rem;
  }

  .btn-continue {
    padding: 0.5rem;
  }
}

.create-email-modal {
  .mat-dialog-container {
    max-height: 100vh !important;
  }
}

.customize-theme-modal {
  .mat-dialog-container {
    width: 700px;
    max-width: 100%;
    max-height: 85vh;
  }
}

.product-modal-container {
  width: 970px;
  height: 700px;
}

.load-url-modal {
  width: 50rem;
  height: fit-content;
  max-height: 80vh;
  border-radius: 0.625rem;
}

.seo-product-container {
  .mat-standard-chip {
    border: 1px solid #dee1e9;
    border-radius: 4px;

    button {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 0;
      background: none;
    }
  }

  .mat-standard-chip.mat-chip {
    background-color: #fafafa;
    color: var(--texto);
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding-right: 0;
  }

  .custom-seo-tooltip.show {
    opacity: 1;
  }
}

.category-autocomplete {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }
}

.delete-product-modal {
  height: 200px;
}

.mat-option-text {
  justify-content: start !important;
  overflow: inherit !important;
}

// Product Select Category
.category-product-select {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0 !important;
    bottom: 0 !important;
  }

  .mat-form-field-ripple {
    background-color: #00000000 !important;
  }
}

.editor-error-description {
  .angular-editor {
    border: 1px solid red !important;
  }
}

.alert-variants {
  position: fixed !important;
  z-index: 99;
  width: 100%;
  top: 5rem;
  background-color: #dc3545;
  padding: 8px 16px;
  font-size: 1rem;
  line-height: 27px;
  color: #fafafa;
}

@media (max-width: 991.88px) {
  .alert-variants {
    top: 8rem;
  }
}

@media (max-width: 767.88px) {
  .alert-variants {
    top: 13.5rem;
  }
}