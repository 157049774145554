@mixin mc-box-shadow($property) {
  -moz-box-shadow: $property;
  -webkit-box-shadow: $property;
  box-shadow: $property;
}

@mixin mc-transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin mc-transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin mc-transition-delay($property) {
    -webkit-transition-delay: $property;
    -moz-transition-delay: $property;
    -o-transition-delay: $property;
    transition-delay: $property;
}

@mixin mc-display-flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin mc-flex-column () {
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
}

@mixin mc-flex-row () {
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
}

@mixin mc-flex-wrap() {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin mc-flex-grow-1() {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

@mixin mc-min-height-min-content() {
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
}

@mixin mc-min-width-max-content() {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}
