$sidebar-color: $gray-600;
$sidebar-color-dark: $gray-300;
$sidebar-background-color: $white;
$sidebar-background-color-dark: $gray-900;
$sidebar-submenu-active-background-color: $gray-200;
$sidebar-submenu-active-background-color-dark: $gray-800;
$sidebar-external-link-color: $gray-600;
$sidebar-external-link-color-dark: $gray-300;
$sidebar-external-link-background-color: $gray-200;
$sidebar-external-link-background-color-dark: $gray-800;
$sidebar-scrollbar-color: #c1c1c1;
$sidebar-scrollbar-color-dark: $gray-700;
$sidebar-scrollbar-background-color: #f5f5f5;
$sidebar-scrollbar-background-color-dark: $black;
$sidebar-profile-color: $white;
$sidebar-profile-color-dark: $white;
$sidebar-profile-background-color: $gray-600;
$sidebar-profile-background-color-dark: $gray-800;
$sidebar-logout-color: $white;
$sidebar-logout-color-dark: $white;
$sidebar-logout-background-color: $gray-700;
$sidebar-logout-background-color-dark: $gray-700;
$colors: (
  --sidebar-color: ($sidebar-color,
    $sidebar-color-dark,
  ),
  --sidebar-background-color: ($sidebar-background-color,
    $sidebar-background-color-dark,
  ),
  --sidebar-submenu-active-background-color: ($sidebar-submenu-active-background-color,
    $sidebar-submenu-active-background-color-dark,
  ),
  --sidebar-external-link-color: ($sidebar-external-link-color,
    $sidebar-external-link-color-dark,
  ),
  --sidebar-external-link-background-color: ($sidebar-external-link-background-color,
    $sidebar-external-link-background-color-dark,
  ),
  --sidebar-scrollbar-color: ($sidebar-scrollbar-color,
    $sidebar-scrollbar-color-dark,
  ),
  --sidebar-scrollbar-background-color: ($sidebar-scrollbar-background-color,
    $sidebar-scrollbar-background-color-dark,
  ),
  --sidebar-profile-color: ($sidebar-profile-color,
    $sidebar-profile-color-dark,
  ),
  --sidebar-profile-background-color: ($sidebar-profile-background-color,
    $sidebar-profile-background-color-dark,
  ),
  --sidebar-logout-color: ($sidebar-logout-color,
    $sidebar-logout-color-dark,
  ),
  --sidebar-logout-background-color: ($sidebar-logout-background-color,
    $sidebar-logout-background-color-dark,
  ),
);

.mc-sidebar--theme-light {

  @each $name,
  $color in $colors {
    #{$name}: nth($color, 2);
  }
}

.mc-sidebar--theme-dark {

  @each $name,
  $color in $colors {
    #{$name}: nth($color, 1);
  }
}

.sidebar-expanded {
  .mc-sidebar {
    @include media-breakpoint-up(md) {
      width: auto;
    }

    &__tree {
      >li {
        >a {
          >.label {
            opacity: 1;
            font-size: 0.875rem;
          }
        }
      }

      &--second-level {
        &.collapsed {
          height: 0 !important;
          opacity: 0;
        }

        &.extended {
          height: auto !important;
          opacity: 1 !important;
        }
      }
    }

    &__external-nav {
      padding: 0.25rem;
    }

    &__profile {
      &-img {
        width: 2.625rem;
        height: 2.625rem;
      }
    }

    &__logout {
      >small {
        opacity: 1;
      }
    }
  }
}

.sidebar-collapsed {
  .mc-sidebar {
    &__tree {
      &--second-level {
        height: 0 !important;
      }
    }

    &__logout {
      >small {
        opacity: 0;
      }
    }
  }
}

.mc-sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 3.125rem;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--secundario);
  z-index: 1001;
  @include mc-transition(width 0.3s ease-in-out);

  &:hover {
    .mc-sidebar__arrow {
      @include mc-transform(translateX(45%));
      @include mc-transition-delay(0s !important);
    }
  }

  .content-logo-iconburguer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .menu-mobile-collapse {
      color: var(--white);
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .menu-mobile-expanded {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--secundario);
  }

  .icons-perfil-logout {
    background-color: var(--white);
  }

  &__brand {
    height: 4.3125rem;
    color: $white;
    overflow: hidden;

    &-sm {
      max-width: 100%;
    }

    &-lg {
      width: 7.5rem;
      height: 2.25rem;
    }
  }

  &__arrow {
    position: absolute;
    padding-right: 1rem;
    right: 0;
    top: 48%;
    width: 3.125rem;
    height: 3.125rem;
    z-index: 1;
    color: var(--white);
    background-color: var(--secundario);
    @include mc-transform(translateX(0));
    @include mc-transition(transform 0.3s ease-in-out);
    @include mc-transition-delay(0.5s);
  }

  &__logout {
    color: var(--secundario);
    height: 4.375rem;
    min-height: 3.125rem;
    font-size: 1.375rem;
    font-weight: 800;

    >small {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--texto);
      white-space: nowrap;
      @include mc-transition(opacity 0.3s ease-in-out);
    }
  }

  &__profile {
    height: 4.375rem;
    min-height: 4.375rem;
    overflow: hidden;

    &-img {
      @include mc-transition(all 0.3s ease-in-out);
      width: 2.125rem;
      height: 2.125rem;
    }

    &-info {
      >span {
        max-width: 8.125rem;
        color: var(--texto);
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  &__menu {
    background-color: var(--sidebar-background-color);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;

    &::-webkit-scrollbar-track {
      background-color: var(--sidebar-scrollbar-background-color);
    }

    &::-webkit-scrollbar {
      width: 0.375rem;
      background-color: var(--sidebar-scrollbar-color);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--sidebar-scrollbar-color);
    }
  }

  &__tree {
    max-height: 20rem;

    >li {
      &.active:not(.has-submenu) {
        background-color: #ebebff44;
        border-radius: 0.375rem;

        >a {
          color: var(--destacados);
        }
      }

      &.has-submenu.active {
        >a {
          background-color: #ebebff22;
          border-radius: 0.375rem;
        }
      }

      >a {
        color: var(--destacados);
        padding: 0.75rem 0.5rem;
        text-decoration: none;

        &:hover {
          color: var(--destacados);
        }

        >.label {
          // margin-left: 0.75rem;
          margin-left: 0.5rem;
          white-space: nowrap;
          opacity: 0;
          @include mc-transition(opacity 0.3s ease-in-out);
        }
      }
    }

    &--first-level {
      >li {
        >a {
          >.label {
            font-weight: 400;
          }
        }
      }
    }

    &--second-level {
      width: fit-content;
      min-width: 100%;
      opacity: 1;
      overflow: hidden;
      @include mc-transition(all 0.3s ease-in-out);

      >li {
        position: relative;

        >a {
          padding: 0.5rem 0.5rem 0.5rem 2rem;

          >.label {
            font-weight: 300;
          }
        }
      }
    }
  }

  &__external-nav {
    padding: 0.25rem 0.125rem;

    .external-link {
      width: 2.375rem;
      height: 2.375rem;
      margin: 0.25rem;
      background-color: var(--sidebar-external-link-background-color);
      color: var(--sidebar-external-link-color);

      &:hover {
        color: $brand;
      }
    }
  }
}

@media screen and (max-width: 767.98px) {
  .mc-sidebar {
    width: 100%;
    height: auto;
    min-height: auto;
    position: relative;
    z-index: 1020;
    overflow: unset;

    &__tree {
      max-height: 20rem;
    }
  }

  .menu-mobile-expanded {
    width: 60%;
    max-width: 250px;
    height: auto !important;
    position: absolute;
    top: 55px;
    right: 0;
  }

  .mc-sidebar__tree>li>a>.label {
    opacity: 1;
  }

  .mc-sidebar__arrow {
    display: none;
  }

  .content-logo-iconburguer {
    justify-content: space-between !important;
    padding: 0 4rem;
  }

  .content-icon-external-link {
    padding-left: 0.5rem;
    padding-right: 2.7rem;
  }

  .mc-sidebar__profile {
    padding-left: 0.75rem;
  }

  .mc-sidebar__logout {
    padding-left: 2.2rem !important;
  }
}