.custom-slider {
    .ng5-slider {
        .ng5-slider-bar {
            background-color: #ccc;
        }
        .ng5-slider-bubble {
            font-size: inherit;
            bottom: 1.25rem;
        }
        .ng5-slider-pointer {
            outline: none;
            background-color: $link-color;
            &:after {
                background-color: transparent;
            }
            &.ng5-slider-active {
                &:after {
                    background-color: #fff;
                }
            }
        }
        .ng5-slider-tick {
            background-color: #ccc;
            &.ng5-slider-selected {
                background-color: $link-color;
            }
        }
        .ng5-slider-selection {
            background-color: $link-color;
        }
    }
}
