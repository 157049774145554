.alert {
    color: #333;
    border-width: .125rem;
    &-info {
        color: $alert-info-color;
        border-color: $alert-info-border;
        background-color: $alert-info-background;
        .close {
            color: $alert-info-color;
            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus {
                color: $alert-info-color;
            }
        }
    }
    &-danger {
        color: $alert-danger-color;
        border-color: $alert-danger-border;
        background-color: $alert-danger-background;
        .close {
            color: $alert-danger-color;
            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus {
                color: $alert-danger-color;
            }
        }
    }

    .close {
        line-height: 0;
    }

    .alert-heading {
        font-weight: 600;
    }
}
