.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  label {
    padding-left: 1.5rem;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin-left: -1.5rem;
  }
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: relative;
  margin-right: .25rem;
  vertical-align: top;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    transition: 240ms;
  }

  &:before {
    position: absolute;
    left: 0;
    background-color: $success;
    transform: scale(0);
  }

  &:after {
    position: relative;
    border: .125rem solid #bbb;
  }

  &:checked:before {
    transform: scale(0.5);
  }

  &:disabled:checked:before {
    background-color: #dee2e6;
  }

  &:checked:after {
    border-color: $success;
  }

  &:disabled:after,
  &:disabled:checked:after {
    border-color: #dee2e6;
  }
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  border: none;
  margin-bottom: -0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus:after {
    border-color: $success;
  }

  &:after {
    content: "";
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: .25rem;
    border: .125rem solid #bbb;
    border-radius: .125rem;
    transition: 240ms;
  }

  &:checked:before {
    content: "";
    position: absolute;
    top: .125rem;
    left: .375rem;
    display: table;
    width: .375rem;
    height: .75rem;
    border: .125rem solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
  }

  &:checked:after {
    background-color: $success;
    border-color: $success;
  }

  &:disabled:after {
    border-color: #dee2e6;
  }

  &:disabled:checked:after {
    background-color: #dee2e6;
    border-color: transparent;
  }
}
