pagination-controls {
    .ngx-pagination {
        margin-bottom: 0;
        font-size: .75rem;
        > li {
            &.current {
                background-color: $primary;
                border-radius: 50%;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                padding: 0;
                line-height: 1.5rem;
                text-align: center;
            }
            > a {
               &:hover {
                   background-color: transparent;
               }
            }
        }
    }
}
