.mc-breadcrumb {
    height: 3.125rem;
    padding: 1rem 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 990;
    &--full-width {
        margin-left: 0;
    }
    .breadcrumbs {
        &__container {
            margin-bottom: 0;
            padding: 0;
            line-height: 1.25rem;
            @include mc-display-flex();
        }
        &__item {
            @include mc-display-flex();
            & + .breadcrumbs__item {
                padding-left: .5rem;
                &::before {
                    display: inline-block;
                    padding-right: .5rem;
                    color: #6c757d;
                    content: "/";
                }
            }
            &--active {

            }
            /*&:first-child {
                > a {
                    font-family: "Font Awesome 5 Pro";
                    &:before {
                        content: "\f015";
                    }
                }
            }*/
        }
    }
}

// MOBILE
@include media-breakpoint-down(sm) {
    .mc-breadcrumb {
        top: 3.125rem;
    }
}
