$bg-off: #e4e4e4;

.status-led {
  display: inline-block;
  margin: .25rem;
  width: .75rem;
  height: .75rem;
  border-radius: 50%;
  background-color: $bg-off;
  box-shadow: inset darken($bg-off, 15%) 1px -2px 3px;
  &--danger {
    background-color: $danger;
    box-shadow: lighten($danger, 30%) 0 -1px 7px 1px, inset darken($danger, 20%) 1px -2px 3px, lighten($danger, 20%) 0 2px 12px;
    &.status-led--intermittent {
      -webkit-animation: danger-neon 0.5s infinite;
      -moz-animation: danger-neon 0.5s infinite;
      animation: danger-neon 0.5s infinite;
    }
  }
  &--warning {
    background-color: $warning;
    box-shadow: lighten($warning, 30%) 0 -1px 7px 1px, inset darken($warning, 20%) 1px -2px 3px, lighten($warning, 20%) 0 2px 12px;
    &.status-led--intermittent {
      -webkit-animation: warning-neon 0.5s infinite;
      -moz-animation: warning-neon 0.5s infinite;
      animation: warning-neon 0.5s infinite
    }
  }
  &--info {
    background-color: $info;
    box-shadow: lighten($info, 30%) 0 -1px 7px 1px, inset darken($info, 20%) 1px -2px 3px, lighten($info, 20%) 0 2px 12px;
    &.status-led--intermittent {
      -webkit-animation: info-neon 0.5s infinite;
      -moz-animation: info-neon 0.5s infinite;
      animation: info-neon 0.5s infinite;
    }
  }
  &--success {
    background-color: $success;
    box-shadow: lighten($success, 10%) 0 -1px 5px 1px, inset darken($success, 20%) 1px -2px 3px, lighten($success, 20%) 0 2px 8px;
    &.status-led--intermittent {
      -webkit-animation: success-neon 0.5s infinite;
      -moz-animation: success-neon 0.5s infinite;
      animation: success-neon 0.5s infinite;
    }
  }
}


@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(warning-neon) {
  from {
    background-color: $warning;
  }
  50% {
    background-color: darken($warning, 10%);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset darken($warning, 20%) 1px -2px 3px, lighten($warning, 40%) 0 2px 0;
  }
  to {
    background-color: $warning;
  }
}

@include keyframes(danger-neon) {
  from {
    background-color: $danger;
  }
  50% {
    background-color: darken($danger, 10%);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset darken($danger, 20%) 1px -2px 3px, lighten($danger, 40%) 0 2px 0;
  }
  to {
    background-color: $danger;
  }
}

@include keyframes(info-neon) {
  from {
    background-color: $info;
  }
  50% {
    background-color: darken($info, 10%);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset darken($info, 20%) 1px -2px 3px, lighten($info, 40%) 0 2px 0;
  }
  to {
    background-color: $info;
  }
}

@include keyframes(success-neon) {
  from {
    background-color: $success;
  }
  50% {
    background-color: darken($success, 10%);
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset darken($success, 20%) 1px -2px 3px, lighten($success, 40%) 0 2px 0;
  }
  to {
    background-color: $success;
  }
}
