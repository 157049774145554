.card {
    border-color: transparent;
    &-title {
    }
    &-text {
        font-weight: 300;
        font-size: .875rem;
        strong {
            font-weight: 600;
        }
    }
    &-header {
        border-bottom: none;
        font-weight: 600;
    }
    &-footer {
        border-top: none;
        font-weight: 600;
        .nav {
            > .nav-item {
                > .nav-link {
                    font-size: .75rem;
                }
            }
        }
    }
    &-body {
        .table {
            font-size: .875rem;
            font-weight: 300;
            margin-bottom: 0;
            > thead {
                > tr {
                    > th, td {
                        border-top: none;
                        border-bottom: none;
                        text-transform: uppercase;
                        font-size: .75rem;
                        color: #666;
                        font-weight: 400;
                    }
                }
            }
            > tbody {
                > tr {
                    &:first-child {
                        > td {
                            border-top: none;
                        }
                    }
                    > td {
                        @include media-breakpoint-down(sm) {
                            font-size: .75rem;
                        }
                    }
                }
            }
            tfoot > {
                tr > {
                    th, td {
                        border-top: none;
                    }
                }
            }
            > thead, tbody {
                td, th {
                    vertical-align: middle;
                    &.condensed {
                        width: 1%;
                        white-space: nowrap;
                    }
                }
            }
        }

    }
}
