.btn {
    text-transform: uppercase;

    &:focus {
        @include mc-box-shadow(none !important);
    }

    &-secondary {
        @include button-variant(#fff, #ccc);
    }
}
