/** Inputs fields */
select.form-control:not([size]):not([multiple]) {
    height: auto;
}

.form-group {
    margin: 0 0 1.5rem 0;
    position: relative;

    .fix-scroll-to {
        position: absolute;
        top: -1.25rem;
    }

    mc-spinner {
        height: .625rem;
        display: inline-block;
        width: 1.5rem;
        position: absolute;
        top: 2.5rem;
        right: .5rem;

        .loader {
            width: 1.25rem;
            margin: -0.625rem auto 0 auto;
            position: absolute;
            top: 50%;
            right: 0;
            left: initial;
        }
    }

    label {
        color: #7d7d7d;
        font-size: 0.95em;
        font-weight: 400;
        margin: 0 0 0.125rem 0;
        position: relative;

        mc-icon {
            color: #339900;
            height: .875rem;
            margin: -0.375rem 0 0 0;
            position: absolute;
            top: 50%;
            right: -1.25rem;
        }
    }

    .form-control {

        &.invalid {
            outline: none;
            box-shadow: none;
            border-color: #FF505D;
            background-color: #F2F2F2;
        }

        &.password-field { padding: .7em 3.7em .7em .7em; }

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #3497DB;
            background-color: #F2F2F2;
        }

        &.textarea {
            resize: none;
            height: 6.25rem;
            width: 100%;
        }
    }

    .mc-error-msg {
        color: #FF505D;
        font-size: .75rem;
        line-height: 1;
        position: absolute;
        bottom: -1rem;
        z-index: 1;
    }

    .error-icon {
        font-size: 1.4em;
        color: #FF505D;
        margin: 1px 0 0 0;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 0.4em;

        &.password-field { right: 1.9em; }

        &.active { opacity: 1; }

        &.no-label { margin: -0.625rem 0 0 0; }
    }

    .visibility-ico {
        position: absolute;
        top: 50%;
        right: 0.4em;
        font-size: 1.4em;
        margin: 1px 0 0 0;
        color: #B3B3B3;
        cursor: pointer;
    }

    &.select {
        .error-icon { right: 1.5rem; }
    }
}

/** Input select */
.input-select-group {
    input.select-active { border-radius: 0.25rem 0.25rem 0 0; }

    .arrow {
        color: #7d7d7d;
        font-size: 1.4em;
        cursor: pointer;
        position: absolute;
        top: 2.25rem;
        right: .625rem;
        -webkit-transition: .2s all;
        -moz-transition: .2s all;
        -o-transition: .2s all;
        transition: .2s all;

        &.focused {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }


    .error-icon { right: 1.7em; }

    .select-list {
        max-height: 12.375rem;
        height: auto;
        overflow: auto;
        background-color: #ffffff;
        border: 1px solid #3497DB;
        border-radius: 0 0 0.25rem 0.25rem;
        position: absolute;
        top: 4.125rem;
        left: 0;
        right: 0;
        z-index: 10;

        ul {
            margin: 0;
            padding: 0;

            li {
                padding: 0 .7em;
                height: 2.5rem;
                line-height: 2.5rem;

                &.active,
                &:hover { background-color: #F2F2F2; }
            }
        }
    }
}

/** Rate componente */
.rate-wrapper {
    position: relative;
    margin: 0 0 1.5rem 0;

    .labels {
        font-size: .875rem;
        font-weight: 400;
        color: #7d7d7d;
        display: flex;
        justify-content: space-between;
        margin: 0 0 .125rem 0;
    }

    .items {
        display: flex;
        border-radius: .25rem;
        overflow: hidden;
        background: -moz-linear-gradient(left, #0483c7 0%, #0895d1 50%, #7db9e8 100%);
        background: -webkit-linear-gradient(left, #0483c7 0%, #0895d1 50%, #7db9e8 100%);
        background: linear-gradient(to right, #0483c7 0%, #0895d1 50%, #7db9e8 100%);

        .item {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            padding: .75rem 0;
            border: 1px solid #dbe0e2;
            color: #ffffff;
            background-color: transparent;
            transition: .2s all;
            cursor: pointer;

            &:first-child { border-radius: .25rem 0 0 .25rem; }

            &:last-child { border-radius: 0 .25rem .25rem 0; }

            &:not(.selected-range) {
                background-color: #f7fafa;
                color: #000000;
            }

            &:hover:not(.selected-range) { background-color: #E3E4E4; }
        }
    }

    .mc-error-msg {
        color: #FF505D;
        font-size: .75rem;
        line-height: 1;
        position: absolute;
        bottom: -1rem;
        z-index: 1;
    }
}
