@import './mixins/main';

.dropdown {
    &-header {
        font-size: 0.75rem;
    }
    &-menu {
        border: none;
        font-size: .875rem;
        @include mc-box-shadow(#{'0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)'});
    }
    &-toggle {
        &--no-arrow {
            &:after {
                content: none;
            }
        }
    }
}